import { MvnCodeInitiate } from "../../lib/const";
import { MvnCodeFE } from "../const";

export const TMF_STUDY_MILESTONE_LANG: {
  NAME_NOUN: MvnCodeFE;
  STATUS_NOUN: MvnCodeFE;
  COMPLETE_RATE_NOUN: MvnCodeFE;
  BASELINE_FINISH_DATE_NOUN: MvnCodeFE;
  PLANNED_FINISH_DATE_NOUN: MvnCodeFE;
  ACTUAL_FINISH_DATE_NOUN: MvnCodeFE;

  IRB_SUBMISSION_TITLE: MvnCodeFE;
  IRB_SUBMISSION_INFORMATION_SUBTITLE: MvnCodeFE;
  IRB_SUBMISSION_MILESTONE_ID: MvnCodeFE;
  IRB_SUBMISSION_MILESTONE_NAME: MvnCodeFE;
  IRB_SUBMISSION_MILESTONE_GROUP: MvnCodeFE;
  IRB_SUBMISSION_TRIAL_DESCRIPTION: MvnCodeFE;
  IRB_SUBMISSION_COUNTRY_DESCRIPTION: MvnCodeFE;
  IRB_SUBMISSION_SITE_DESCRIPTION: MvnCodeFE;

  IRB_SUBMISSION_MILESTONE_DATE_SUBTITLE: MvnCodeFE;
  IRB_SUBMISSION_BASELINE_START_DATE: MvnCodeFE;
  IRB_SUBMISSION_PLANNED_START_DATE: MvnCodeFE;
  IRB_SUBMISSION_ACTUAL_START_DATE: MvnCodeFE;
  IRB_SUBMISSION_BASELINE_FINISH_DATE: MvnCodeFE;
  IRB_SUBMISSION_PLANNED_FINISH_DATE: MvnCodeFE;
  IRB_SUBMISSION_ACTUAL_FINISH_DATE: MvnCodeFE;
} = {
  NAME_NOUN: {
    ko: "Study Milestone",
    en: "Study Milestone",
  },
  STATUS_NOUN: {
    ko: "Status",
    en: "Status",
  },
  COMPLETE_RATE_NOUN: {
    ko: "% Complete",
    en: "% Complete",
  },
  BASELINE_FINISH_DATE_NOUN: {
    ko: "Baseline Finish Date",
    en: "Baseline Finish Date",
  },
  PLANNED_FINISH_DATE_NOUN: {
    ko: "Planned Finish Date",
    en: "Planned Finish Date",
  },
  ACTUAL_FINISH_DATE_NOUN: {
    ko: "Actual Finish Date",
    en: "Actual Finish Date",
  },

  IRB_SUBMISSION_TITLE: {
    ko: "Milestone - IRB Submission",
    en: "Milestone - IRB Submission",
  },
  IRB_SUBMISSION_INFORMATION_SUBTITLE: {
    ko: "Information",
    en: "Information",
  },
  IRB_SUBMISSION_MILESTONE_ID: {
    ko: "Milestone ID",
    en: "Milestone ID",
  },
  IRB_SUBMISSION_MILESTONE_NAME: {
    ko: "Milestone Name",
    en: "Milestone Name",
  },
  IRB_SUBMISSION_MILESTONE_GROUP: {
    ko: "Milestone Group",
    en: "Milestone Group",
  },
  IRB_SUBMISSION_TRIAL_DESCRIPTION: {
    ko: "Study Description",
    en: "Study Description",
  },
  IRB_SUBMISSION_COUNTRY_DESCRIPTION: {
    ko: "Country Description",
    en: "Country Description",
  },
  IRB_SUBMISSION_SITE_DESCRIPTION: {
    ko: "Site Description",
    en: "Site Description",
  },

  IRB_SUBMISSION_MILESTONE_DATE_SUBTITLE: {
    ko: "Milestone Date",
    en: "Milestone Date",
  },
  IRB_SUBMISSION_BASELINE_START_DATE: {
    ko: "Baseline Start Date",
    en: "Baseline Start Date",
  },
  IRB_SUBMISSION_PLANNED_START_DATE: {
    ko: "Planned Start Date",
    en: "Planned Start Date",
  },
  IRB_SUBMISSION_ACTUAL_START_DATE: {
    ko: "Actual Start Date",
    en: "Actual Start Date",
  },
  IRB_SUBMISSION_BASELINE_FINISH_DATE: {
    ko: "Baseline Finish Date",
    en: "Baseline Finish Date",
  },
  IRB_SUBMISSION_PLANNED_FINISH_DATE: {
    ko: "Planned Finish Date",
    en: "Planned Finish Date",
  },
  IRB_SUBMISSION_ACTUAL_FINISH_DATE: {
    ko: "Actual Finish Date",
    en: "Actual Finish Date",
  },
};

MvnCodeInitiate(TMF_STUDY_MILESTONE_LANG);
